import { styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { loadInvoicePayComponent } from "../../features/contentful/contentfulThunkApi";
import { useDispatch, useSelector } from "react-redux";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulRichTextOptions } from "../../utils/Helper";
import { Box } from "@mui/material";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";

const BannerContainer = styled('div')(
    ({ aboveFoldBGImage, theme: { palette: { primary }, breakpoints }}) => `
    background-color: ${primary.main};
    background-image: url(${aboveFoldBGImage});
    background-size: cover;
    height: 200px;
    ${[breakpoints.down('sm')]} {
        background-image: unset;
    }
    `
);

const InvoicePayInfoPage = () => {
    const { invoicePayComponent } = useSelector((store) => store?.contentful);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if ( invoicePayComponent && Object.keys(invoicePayComponent).length === 0)
            dispatch(loadInvoicePayComponent());
    }, [invoicePayComponent, dispatch]);

    const handlePayButton = () => {
        history.push('/invoicepay/paynow');
    }
    return(
        <>
            <BannerContainer aboveFoldBGImage={invoicePayComponent?.invoiceBanner?.fields?.file?.url}>
                <Typography variant={'h1'} color={'neutral.white'} py={'40px'} px={{xs:'48px'}}>{invoicePayComponent?.pageTitle}</Typography>
            </BannerContainer>
            <Box margin={'48px'}>
                <div>
                    {documentToReactComponents(invoicePayComponent?.invoicePayTextBody, contentfulRichTextOptions)}
                </div>
                <Box mt={4} mb={4}>
                    <Button
                        buttonType='mds-primary'
                        data-testid="invoicePageBtn"
                        sx={{ padding: "16px 32px", textTransform: "none", }}
                        onClick={handlePayButton}
                    >
                        {invoicePayComponent?.invoicePayButtonText}
                    </Button>
                </Box>
                <div>
                    {documentToReactComponents(invoicePayComponent?.invoicePayTextBodyLower, contentfulRichTextOptions)}
                </div>
            </Box>
        </>
    )
}

export default InvoicePayInfoPage;