import { useState } from 'react';
import { Box, Card, CardContent, Container, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TypographyH1 } from '../../../assets/typographyTheme';
import { Button } from '../../Button';
import { FormLabel,
        helperTextStyles, 
        textFieldStyles, 
        validateEmail, 
        validateName, 
        validateRequiredField, 
        validateZipcode } from '../../Onboarding/Helper';
import { validateInvoiceNumber, validatePaymentAmount } from '../Helper';
import useAddPaymentMethod from '../../../hooks/useAddPaymentMethod';
import { ModalComponent } from '../../ModalComponent';
import { PAYMENT_OPTIONS } from '../../../constants';

const RequiredSymbol = styled('span')({
    color: 'red',
});

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    display:"flex",
    justifyContent:'left',
    marginY: "24px",
}

const cardStyles = {
    variant:"outlined",
    bgcolor: 'neutral.white',
    borderRadius: '16px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.50)'
};

export const InvoicePayForm = ({ handleErrorMsg, setShowSpinner }) => {    
    const defaultInputFields = {
        firstName: '',
        lastName: '',
        email: '',
        invoiceNumber: '',
        zipcode: '',
        shippingOrgName: '',
        paymentAmount: '',
    };
    
    const [errors, setErrors] = useState({});
    const [inputFields, setInputFields] = useState(defaultInputFields);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');

    const {
        showPaymentForm,
        processing,
        StripeForm,
        handleClosePaymentModal,
        setPaymentType,
        onPayByInvoice
    } = useAddPaymentMethod(handleErrorMsg);

    const fieldValidations = {
        firstName: validateName,
        lastName: validateName,
        email: validateEmail,
        invoiceNumber: validateInvoiceNumber,
        zipcode: validateZipcode,
        shippingOrgName: validateRequiredField,
        paymentAmount: validatePaymentAmount
    }

    const validateInputFields = (field, value) => {
        const validationFunction = fieldValidations[field];
        return validationFunction ? validationFunction(value) : "";
    };

    const formatToUSD = (value) => {
        // Remove any non-numeric characters except period
        const number = parseFloat(value.replace(/[^0-9.]/g, ""));
        if (isNaN(number)) return "";
        
        // Format number to USD style with two decimal places
        return `$${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };
    

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateInputFields(name, value);
        setErrors({ ...errors, [name]: errorMsg });

        if (!errorMsg && name === "paymentAmount") {
            const formattedValue = formatToUSD(value);
            setInputFields({ ...inputFields, [name]: formattedValue });
        }
    };

    const createTextField = (key, label, value, indicatorNumber) => {
        return (
            <>
                <Box display='flex' alignItems='center'>
                { indicatorNumber && <Box
                    sx={{
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#079AE0',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 18,
                        mr: 1,
                        mb: 1
                    }}
                    >
                       {indicatorNumber}
                    </Box>
                    }
                    <FormLabel label={label} data-testid={key} />
                </Box>
                <TextField
                    id={key}
                    name={key}
                    data-testid={key}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors[key])}
                    helperText={errors[key]}
                    sx={textFieldStyles(errors[key])}
                    FormHelperTextProps={helperTextStyles}
                />
            </>
        )
    };

    const InfoText = ({ text }) => {
        return (
            <Typography color='#383D47' fontSize='14px' fontWeight='400' paddingTop='7px'>{text}</Typography>
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateInputFields(fieldName, inputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setInvoiceNumber(inputFields.invoiceNumber);
        setPaymentAmount(inputFields.paymentAmount);
        setPaymentType(PAYMENT_OPTIONS.PAY_INVOICE);

        const customerDetails = {
            email: inputFields.email,
            name: `${inputFields.firstName} ${inputFields.lastName}`,
            metadata: {
                zipcode: inputFields.zipcode,
                organizationName: inputFields.shippingOrgName
            }
        }

        await onPayByInvoice(customerDetails);
    }

    return (
        <>
            <Box paddingTop='16px' display='flex' flexDirection='row' gap={2} maxWidth={'450px'} >
                <Card sx={cardStyles}>
                    <CardContent sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Container>
                            <Box display='flex' flexDirection='column' gap={'24px'} >
                                <TypographyH1>Pay an invoice</TypographyH1>
                                <Typography><RequiredSymbol>*</RequiredSymbol>All fields are required</Typography>
                                <form onSubmit={handleSubmit} >
                                    <Grid container rowSpacing={'16px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" style={{ marginBottom: '8px' }}>
                                        <Grid item xs={12} sm={6} >
                                            {createTextField("firstName", "Your first name", inputFields.firstName)}
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            {createTextField("lastName", "Your last name", inputFields.lastName)}
                                        </Grid>
                                    </Grid>
                                    <Grid container rowSpacing={'16px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start">
                                        <Grid item xs={12} >
                                            {createTextField("email", "Your email address", inputFields.email)}
                                        </Grid>
                                        <Grid item xs={12} >
                                            {createTextField("invoiceNumber", "Invoice Number", inputFields.invoiceNumber, 1)}
                                        </Grid>
                                        <Grid item xs={12} >
                                            {createTextField("zipcode", "ZIP code on the billing address", inputFields.zipcode, 2)}
                                            <InfoText text='Must match the billing address as it appears on the invoice' />
                                        </Grid>
                                        <Grid item xs={12}  gap={'7px'}>
                                            {createTextField("shippingOrgName", "Organization name on the shipping address", inputFields.shippingOrgName, 3)}
                                            <InfoText text='Must match the shipping address as it appears on the invoice.' />
                                        </Grid>
                                        <Grid item xs={12} >
                                            {createTextField("paymentAmount", "Amount to pay", inputFields.paymentAmount)}
                                            <InfoText text='No partial payments. Enter the entire amount of the invoice.' />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={buttonsContainerSxStyles}>
                                        <Button 
                                            type="submit"
                                            id="submit"
                                            sx={{ padding: "16px 24px", textTransform: "none" }}
                                            buttonType="mds-primary"
                                            data-testid='continueBtn'
                                            loading={processing}
                                        >
                                            Continue
                                        </Button>
                                    </Grid>
                                </form>
                            </Box>
                        </Container>
                    </CardContent>
                </Card>
            </Box>

            {showPaymentForm && <ModalComponent
                openModal={showPaymentForm}
                closeModalHandler={handleClosePaymentModal}
                modalContent={<StripeForm invoiceNumber={invoiceNumber} paymentAmount={paymentAmount} setShowSpinner={setShowSpinner}/>}
                style={{ 
                    maxWidth: '521px',
                    width: 'auto',
                    padding: '24px'
                }}
            />}
        </>
    );
};
