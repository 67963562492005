import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { TypographyH2 } from "../assets/typographyTheme";
import invoicePayInfographic from "../assets/images/invoicePayInfographic.png";
import { InvoicePayForm } from "../components/Payments/InvoicePay/InvoicePayForm";
import { AlertMessage, Spinner } from "../components";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";

const InfoTextBlock = ({ children }) => {
    return (
      <Box display="block" mb={1}>
        {children}
      </Box>
    );
};

const ImportantInformationBlock = () => {
    return (
        <Box display='flex' flexDirection='column' gap={2} maxWidth={'450px'} paddingTop={'16px'}>
            <Typography>Locate the following information on your invoice and enter it into the form on the left.</Typography>
            <img src={invoicePayInfographic} width={'450px'} height={'334px'}></img>
            <TypographyH2>Important information</TypographyH2>
            <Typography textAlign="left">
                <InfoTextBlock>
                    We do not accept partial payments. Please enter the full amount as listed on your invoice.
                </InfoTextBlock>
                <InfoTextBlock>
                    If you have payment terms, be sure to account for the correct amount if paying within the specified date.
                </InfoTextBlock>
                <InfoTextBlock>
                    If you encounter any difficulties with payment processing or have further questions,
                    our dedicated customer support team is here to assist you at 1-866-MODERNA (1-866-663-3762)
                    between the hours of 8:00AM - 8:00PM EST, Monday-Friday (not including Holidays).
                </InfoTextBlock>
            </Typography>
        </Box>
    )
}

const InvoicePayPage = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const [showSpinner, setShowSpinner] = useState(false);

    const errorMsgHandler = (errorMsg) => {
        setErrorMsg(errorMsg);
        alertRef.current?.openAlert(errorMsg);
    }

    return (
        <Box backgroundColor='#F9F9FA'>
            {showSpinner && <FullPageSpinner message="Processing payment. Please do not refresh or leave this page." />}
            <AlertMessage
                variant={"filled"}
                type={"error"}
                message={errorMsg}
                sx={{ top: 120 }}
                ref={alertRef}
            />
            <Box display='flex' gap={4} justifyContent={'center'} paddingTop={'88px'} paddingBottom={'88px'}>
                <InvoicePayForm handleErrorMsg={errorMsgHandler} setShowSpinner={setShowSpinner} />
                <ImportantInformationBlock />
            </Box>
        </Box>
    )
}

export default InvoicePayPage
