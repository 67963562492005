import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Container, Grid, TextField } from "@mui/material";
import crossIcon from "../../../assets/images/crossIcon.svg";
import { Button } from "../../Button";
import { AlertMessage, Spinner } from "../../../components";
import { getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { FormLabel, helperTextStyles, textFieldStyles, validateName, validatePhoneNumber } from "../Helper";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";
import { RequiredSymbol } from "../Helper";

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    display:"flex",
    justifyContent:'left',
    marginY: "24px",
}

export const ContactInfoForm = () => {
    const defaultInputFields = {
        firstName: '',
        lastName: '',
    };

    const defaultPhoneNumber = {
        phoneNumber: '',
        extension: '',
        type: 'work',
    };

    const [inputFields, setInputFields] = useState(defaultInputFields);
    const [errors, setErrors] = useState({ phoneNumbers: [{ phoneNumber: '', extension: '', type: '' }] });
    const [phoneNumbers, setPhoneNumbers] = useState([defaultPhoneNumber]);
    const dispatch = useDispatch();
    const requiredFields = ['firstName', 'lastName'];
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';

    useEffect(() => {
        const checkIsFormValid = () => {
            const requiredFieldsFilled = requiredFields.every((field) => inputFields[field].trim().length !== 0);
    
            const atLeastOnePhoneNumber = phoneNumbers.some(phone => phone.phoneNumber.trim().length > 0);
            const phoneNumbersValid = phoneNumbers.every((phone, index) => {
                const phoneErrors = errors.phoneNumbers?.[index] || {};
                return !phone.phoneNumber.trim() || phoneErrors.phoneNumber === "";
            });
    
            const nonPhoneNumberErrors = { ...errors };
            delete nonPhoneNumberErrors.phoneNumbers;
            const noErrors = Object.values(nonPhoneNumberErrors).every(errorMsg => errorMsg === "");
    
            const noPhoneNumberErrors = errors?.phoneNumbers?.every(phoneError => {
                return phoneError && Object?.values(phoneError)?.every(errorMsg => errorMsg === "");
            });
    
            return requiredFieldsFilled && atLeastOnePhoneNumber && phoneNumbersValid && noErrors && noPhoneNumberErrors;
        };
        checkIsFormValid();
    
    }, [inputFields, phoneNumbers, errors]);

    // code below may need to be modified when integrating with contact info api ECOM-2260
    // const updateUserRequestBody = {
    //     firstName: inputFields?.firstName,
    //     lastName: inputFields?.lastName,
    //     phones: phoneNumbers,
    // }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};
        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateInputFields(fieldName, inputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        phoneNumbers.forEach((phone, index) => {
            const phoneErrorMsg = validateInputFields('phoneNumber', phone.phoneNumber);
            if (phoneErrorMsg) {
                if (!validationErrors.phoneNumbers) {
                    validationErrors.phoneNumbers = [];
                }
                validationErrors.phoneNumbers[index] = { ...validationErrors.phoneNumbers[index], phoneNumber: phoneErrorMsg };
            }
        });

        if (Object.keys(validationErrors).length > 0 || (validationErrors.phoneNumbers && validationErrors.phoneNumbers.length > 0)) {
            setErrors(validationErrors);
            return;
        }

        // code below to be modified when integrating with contact info api ECOM-2260
        console.log('handling submit...')
        // try {
        //     setIsLoading(true);
        //     const response = await updateUserDetailsApi(updateUserRequestBody);
        //     if (response?.status === 200) {
        //         dispatch(setUserStatus(response?.data?.userStatus));
        //         setInputFields(defaultInputFields);
        //         setPhoneNumbers([defaultPhoneNumber]);
        //         setIsLoading(false);
        //         dispatch(getUserByEmail({ emailAddress: response?.data?.email }))
        //         .unwrap()
        //         .then((res) => {
        //             if (res) {
        //                 localStorage.setItem("currentUser", JSON.stringify(res));
        //             }
        //         });
        //     }
        // } catch (error) {
        //     const errMsg = error?.response?.data?.message;
        //     setErrorMsg(errMsg || generalApiError);
        //     alertRef.current?.openAlert(error);
        // } finally {
        //     setIsLoading(false);
        // }
    };

    const handlePhoneChange = (index, value, name) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index][name] = value;
        setPhoneNumbers(newPhoneNumbers);

        const errorMsg = validateInputFields(name, value);
        const newPhoneErrors = [...(errors.phoneNumbers ?? [])];
        newPhoneErrors[index] = { ...newPhoneErrors[index], [name]: errorMsg };
        setErrors({ ...errors, phoneNumbers: newPhoneErrors });
    };

    const handleAddPhoneNumber = () => {
        setPhoneNumbers([...phoneNumbers, {...defaultPhoneNumber}]);
        setErrors({ ...errors, phoneNumbers: [...errors.phoneNumbers, { phoneNumber: '', extension: '', type: '' }] });
    };

    const handleRemovePhoneNumber = (index) => {
        const updatedPhoneNumbers = [...phoneNumbers.slice(0, index), ...phoneNumbers.slice(index + 1)];
        const updatedPhoneErrors = [...errors.phoneNumbers.slice(0, index), ...errors.phoneNumbers.slice(index + 1)];
        setPhoneNumbers(updatedPhoneNumbers);
        setErrors({ ...errors, phoneNumbers: updatedPhoneErrors });
    };

    const handleBlur = (event, index) => {
        const { name, value } = event.target;
        const errorMsg = validateInputFields(name, value);
        const newErrors = { ...errors };
  
        if (index !== undefined) {
            newErrors.phoneNumbers[index] = { ...newErrors.phoneNumbers[index], [name]: errorMsg };
        } else {
            newErrors[name] = errorMsg;
        }
        
        setErrors(newErrors);
    };
    
    const fieldValidations = {
        firstName: validateName,
        lastName: validateName,
    };
    
    const validateInputFields = (field, value, phoneNumbers = []) => {
        if (field === 'phoneNumber') {
            return validatePhoneNumber(value, phoneNumbers);
        }

        const validationFunction = fieldValidations[field];
        return validationFunction ? validationFunction(value) : "";
    };

    const createTextField = (key, label, placeholder, value, onChange, onBlur, isRequired, error, index=null) => {
        const errorKey = index !== null ? `${key}${index}` : key;

        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={errorKey}
                    name={errorKey}
                    data-testid={errorKey}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    error={Boolean(error) || Boolean(errors[errorKey])}
                    helperText={error || errors[errorKey]}
                    sx={textFieldStyles(errors[errorKey])}
                    FormHelperTextProps={helperTextStyles}
                />
            </>
        )
    };

    const PhoneNumberForm = (index) => {
        const phoneNumber = phoneNumbers[index];
        const showTitles = index === 0;
        
        // If more than 1 phone number, shrink to fit container
        const phoneNumberGridSize = phoneNumbers.length > 1 ? 5 : 6;
        return (
            <Grid key={index} container columnSpacing={2} justifyContent="start" alignItems="flex-start">
                <Grid item xs={12} sm={phoneNumberGridSize}>
                    {createTextField("phoneNumber", showTitles ? "Your phone number" : "", "123-456-7890", phoneNumber.phoneNumber, (e) => handlePhoneChange(index, e.target.value, 'phoneNumber'),
                    handleBlur, showTitles, errors.phoneNumbers?.[index]?.phoneNumber, index)}
                </Grid>
                <Grid item xs={12} sm={phoneNumberGridSize}>
                    {createTextField("extension", showTitles ? "Ext" : "", "123", phoneNumber.extension, (e) => handlePhoneChange(index, e.target.value, 'extension'), handleBlur, false)}
                </Grid>
                {phoneNumbers.length > 1 && index !== 0 && (
                    <Grid item xs={12} sm={1} paddingTop='15px' sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img width='30px' alt="crossIcon" src={crossIcon} onClick={() => handleRemovePhoneNumber(index)} data-testid='crossicon' style={{ cursor: 'pointer' }} />
                    </Grid>
                )}
            </Grid>
        );
    };
    
    return (
        <Box maxWidth={'700px'}>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <form onSubmit={handleSubmit}>
                <Container key='onboardingContactInfo' data-testid='onboardingContactInfo'>
                    <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2} width={'700px'}>
                        <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Contact Information</Typography>
                        <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A"><RequiredSymbol>*</RequiredSymbol> Required field</Typography>
                    </Box>
                    <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" style={{ marginBottom: '8px' }}>
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("firstName", "Your first name", "Jonathan", inputFields.firstName, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("lastName", "Your last name", "Doe", inputFields.lastName,  handleChange, handleBlur, true)}
                        </Grid>
                    </Grid>

                    {phoneNumbers.map((phoneNumber, index) => PhoneNumberForm(index))}
                    <Button
                        id="addPhoneNumberLink"
                        sx={{ textTransform: "none" }}
                        buttonType="link"
                        data-testid='addPhoneNumberBtn'
                        onClick={handleAddPhoneNumber}
                    >
                        Add another phone number
                    </Button>

                    <Grid sx={buttonsContainerSxStyles}>
                        <Button 
                            type="submit"
                            id="submit"
                            sx={{ padding: "16px 24px", textTransform: "none" }}
                            buttonType="mds-primary"
                            data-testid='submitBtn'
                        >
                            Continue
                        </Button>
                    </Grid>
                </Container>
            </form>
        </Box>
    )
}