import { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Container, Grid, TextField, Snackbar, SnackbarContent, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import { Button } from "../../Button";
import { MEDICAL_IDENTIFIERS, US_STATES } from "../../../constants";
import CheckIcon from "../../../assets/images/checkIconWhite.svg"
import Dropdown from "../Dropdown";
import { formatStateOptions, validateCity, validateDEA, validateEmail, validateHIN, validateNPI, validateRequiredField, validateShippingPhoneNumber, validateTDDD, validateZipcode } from "../Helper";
import MedicalLicenseFormModal from "./MedicalLicenseFormModal";
import MedicalLicenseSelectionModal from "./MedicalLicenseSelectionModal";
import MedicalLicenseCard from "./MedicalLicenseCard";
import { getSAPUserByEmail, getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { AlertMessage, Spinner } from "../../../components";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";
import { setCartData, setReservationData } from "../../../features/commerce/commerceSlice";
import { getLicenseName } from "../../../utils/HelperFunctions/OrderAndReservationHelper";
import { getOrderableProducts } from "../../../utils/Helper";

const RequiredSymbol = styled('span')({
    color: 'red',
});

const buttonsContainerSxStyles = {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'left',
    gap: '24px'
}

const helperTextStyles = {
    sx: {
      '&.Mui-error': {
        color: '#E22929',
        fontFamily: 'Aeonik Regular',
        marginLeft: '0',
        fontSize: '14px',
        letterSpacing: '-0.28px'
      },
    },
}

const snackbarStyles = {
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: '#079AE0',
    padding: '10px 32px',
    boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.16)',
    '.MuiSnackbarContent-message': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '13px'
    }
}

const FormLabel = ({ label, isRequired }) => {
    return (
        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
            {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
        </Typography>
    )
}

export const ShippingAddressForm = ({ 
    tabIndex,
    onTabChange = () => {},
    handleNewAddresses,
    closeModal,
    isOrdering=false,
    setExternalProcessing,
    isAddingAdditionalLocation=false,
    errorMsgHandler }) => {
    
    const theme = useTheme()

    const [savedShippingAddresses, setSavedShippingAddresses] = useState([]);
    const [selectedMedicalLicense, setSelectedMedicalLicense] = useState({});
    const dispatch = useDispatch();
    const [medicalLicensesForSelectedState, setMedicalLicensesForSelectedState] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [openLicenseFormModal, setOpenLicenseFormModal] = useState(false);
    const [openLicenseSelectionModal, setOpenLicenseSelectionModal] = useState(false);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const userData = useSelector((store) => store?.commerce?.userData);
    const userAddresses = userData?.addresses || [];
    const userStatus = useSelector((store) => store?.globalStates?.userStatus)
    const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
    const orderableProducts = getOrderableProducts();
    const { reservationProducts } = useSelector((store) => store?.commerce);
    const { cartData }  = useSelector((store) => store?.commerce);
    const { reservationData } = useSelector((store) => store?.commerce);

    const getDefaultAddressFields = () => {
        let defaultAddressFields = {
            dbaName: '',
            contactEmail: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            zipcodeExt: '',
            tddd: '',
            contactFirstName: '',
            contactLastName: '',
            hin: '',
            dea: '',
            npi: ''
        }

        return defaultAddressFields
    }
    const [addressInputFields, setAddressInputFields] = useState(getDefaultAddressFields());

    const getMedicalLicenses = (licenses) => {
        return licenses.filter(license => license.issuer !== "DEA" && license.issuer !== "HIN" && license.issuer !== "NPI")
    };

    const medicalLicenses = getMedicalLicenses(useSelector(store => store?.commerce?.userData.licenses || []));
    const getSnackbarMessage = () => {
        return (
            <>
                <img src={CheckIcon} alt="success icon" width={'32px'} height={'32px'}/>
                <Typography fontFamily={'Aeonik Regular'} fontSize={'20px'} fontWeight={'700'}>
                    {snackbarMessage}, saved
                </Typography>
            </>
        )
    }

    const USStates = formatStateOptions(US_STATES);
    const getRequiredFields = () => {
        let requiredFields = ['dbaName', 'addressLine1', 'city', 'state', 'zipcode', 'contactEmail', 'phoneNumber', 'contactFirstName', 'contactLastName'];
        return requiredFields;
    }
    

    const textFieldStyles = (name) => {
        return {
            "& input": {
                fontFamily: 'Aeonik Regular',
                padding: "12px",
                height: '24px',
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
                '&.Mui-focused fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
            },
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...addressInputFields, [name]: value };
        setAddressInputFields(newInputFields);
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateField(name, value);
        setErrors({ ...errors, [name]: errorMsg });
    };

    const handleStateSelection = (event) => {
        const { value } = event.target
        const newInputFields = { ...addressInputFields, state: value }
        if (value !== 'OH') {
            newInputFields.tddd = ''
        }
        setAddressInputFields(newInputFields)
        setMedicalLicensesForSelectedState(medicalLicenses.filter(license => license.state === value))
    
        const newErrors = { ...errors, state: "" }
        setErrors(newErrors);
    };

    const handleAddAddress = async () => {
        const validationErrors = getValidationErrors()
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return
        }

        try {
            setIsLoading(true);
            const response = await updateUserDetailsApi(getUpdateUserRequestBody());
            if (response?.status === 200) {
                const newAddress = {
                    dba: addressInputFields.dbaName,
                    line1: addressInputFields.addressLine1,
                    line2: addressInputFields.addressLine2,
                    city: addressInputFields.city,
                    state: addressInputFields.state,
                    zip: addressInputFields.zip,
                }
                setSavedShippingAddresses(savedAddresses => [...savedAddresses, newAddress])
                setSnackbarMessage(addressInputFields.dbaName)
                setIsSnackbarOpen(true)
                setAddressInputFields(getDefaultAddressFields());
                setSelectedMedicalLicense({})
                setIsAddingAddress(true)
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message;
            setErrorMsg(errMsg || generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCancelAddAddress = async () => {
        const response = await dispatch(getUserByEmail({ emailAddress: userData.email })).unwrap();
        dispatch(setUserStatus(response?.userStatus));
        onTabChange(tabIndex + 1);
    }

    const handleCloseLicenseFormModal = () => {
        setOpenLicenseFormModal(false)
    } 
    
    const handleCloseLicenseSelectionModal = () => {
        setOpenLicenseSelectionModal(false)
    }

    const handleOnAddLicense = () => {
        if (medicalLicensesForSelectedState.length) {
            setOpenLicenseSelectionModal(true)
        } else {
            setOpenLicenseFormModal(true)
        }
    }

    const removeLicense = () => {
        setSelectedMedicalLicense({})
    }

    const validateMedicalLicense = () => {
        const errors = {};
        const isLicenseSelected = Object.keys(selectedMedicalLicense).length > 0;
        
        if (!isLicenseSelected) {
            errors.medicalLicense = "Please add a medical license to continue.";
        } 
        else if (selectedMedicalLicense.state !== addressInputFields.state) {
            errors.medicalLicense = "The Medical license's state must match the Shipping address.";
        }
        
        return errors;
    };

    const getValidationErrors = () => {
        let validationErrors = validateMedicalLicense()

        Object.keys(addressInputFields).forEach((fieldName) => {
            const errorMsg = validateField(fieldName, addressInputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        return validationErrors
    }

    const appendMedicalIdentifiers = (licenses) => {
        if (addressInputFields.hin.trim() !== '') {
            licenses.push({
                licenseType: MEDICAL_IDENTIFIERS.HIN,
                number: addressInputFields.hin,
            });
        }
        if (addressInputFields.dea.trim() !== '') {
            licenses.push({
                licenseType: MEDICAL_IDENTIFIERS.DEA,
                number: addressInputFields.dea,
            });
        }
        if (addressInputFields.npi.trim() !== '') {
            licenses.push({
                licenseType: MEDICAL_IDENTIFIERS.NPI,
                number: addressInputFields.npi,
            });
        }
    };

    const getUpdateUserRequestBody = () => {
        let updateUserRequestBody = {
            userId: userData?.userId,
            updateType: "shipping",
            organization: {
                orgType: "ship_to",
                dbaName: addressInputFields.dbaName, 
                contactEmail: addressInputFields.contactEmail,
                contactPhone: addressInputFields.phoneNumber,
                contactFirstName: addressInputFields.contactFirstName,
                contactLastName: addressInputFields.contactLastName,
                address: {
                    addressType: "ship_to",
                    line1: addressInputFields.addressLine1,
                    line2: addressInputFields.addressLine2,
                    city: addressInputFields.city,
                    state: addressInputFields.state,
                    zip: addressInputFields.zipcode,
                    zipExtension: addressInputFields.zipcodeExt,
                    tddd: addressInputFields.tddd, 
                    licenses: [
                        {
                            licenseType: selectedMedicalLicense.licenseType, 
                            firstName: selectedMedicalLicense.firstName,
                            lastName: selectedMedicalLicense.lastName,
                            name: selectedMedicalLicense.name,
                            number: selectedMedicalLicense.number, 
                            state: selectedMedicalLicense.state, 
                            expiration: dayjs(selectedMedicalLicense.expiration, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                        }
                    ]
                } 
            }
        }

        appendMedicalIdentifiers(updateUserRequestBody.organization.address.licenses);

        return updateUserRequestBody
    }

    const filterNewAddresses = (previousAddresses, newResponseAddresses) => {
        const prevAddrIds = new Set(previousAddresses.map(addr => addr.id));

        return newResponseAddresses.filter(newAddr =>
          !prevAddrIds.has(newAddr.id) && newAddr.addressType === 'ship_to');
    }

    const handleAdditionalAddress = (address) => {
        if(isOrdering){
            const newCartLineItems = orderableProducts?.map(product => {
                const internalCartLineItemId = Math.random().toString(36).substr(2, 9);
                return {
                    internalCartLineItemId: internalCartLineItemId,
                    cartId: cartData.cartId,
                    shipToAddress: {addressId: String(address?.id)},
                    shipToOrganizationId: String(address?.organizationId),
                    productId: isCatalogServiceEnabled ? product?.materialMasterId : product?.code,
                    quantity: 0,
                    purchaseOrderNumber: "",
                    healthcareLicense: {
                        name: getLicenseName(selectedMedicalLicense),
                        state: selectedMedicalLicense?.state,
                        licenseNumber: selectedMedicalLicense?.number,
                        expirationDate: selectedMedicalLicense?.expiration,
                        licenseType: "STATE"
                    },
                    priceSnapshotId:"",
                };
            });

            const updatedCartLineItems = cartData?.cartLineItems? [...cartData?.cartLineItems, ...newCartLineItems] : [...newCartLineItems];
            dispatch(setCartData({
                cartLineItems: updatedCartLineItems
            }));
        } else {
            const newReservationEntries = reservationProducts?.map(product => {
                const uniqueSuffix = Math.random().toString(36).substr(2, 9);
                const reservationEntryId = `${product.code}-${uniqueSuffix}`;
                return {
                    reservationEntryId: reservationEntryId,
                    reservationId: reservationData.reservation.reservationId,
                    deliveryAddressId: address.id,
                    productId: product.code,
                    quantity: 0 
                };
            });

            // Combine the existing reservation entries with the new entries for the additional address
            const updatedReservationEntries = [
                ...reservationData.reservationEntries,
                ...newReservationEntries
            ];

            // Dispatch an action to update the reservation entries in the Redux store
            dispatch(setReservationData({
                ...reservationData,
                reservationEntries: updatedReservationEntries
            }));
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = getValidationErrors()

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        try {
            userStatus === 'ACTIVE' ? setExternalProcessing(true) : setIsLoading(true);
            const response = await updateUserDetailsApi(getUpdateUserRequestBody());
            if (response?.status === 200) {
                const userRes = await dispatch(getUserByEmail({ emailAddress: response.data?.email })).unwrap();
                let newAddresses = filterNewAddresses(userAddresses, userRes?.addresses);
                if (handleNewAddresses) {
                    // Ideally BE API will return the new addresses to FE, here it's workaround
                    let newHealthcareLicense = {
                        name: getLicenseName(selectedMedicalLicense),
                        state: selectedMedicalLicense.state,
                        licenseNumber: selectedMedicalLicense.number, 
                        expirationDate: dayjs(selectedMedicalLicense.expiration, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                        licenseType: "STATE",
                    }
                    newAddresses[0] = {
                        ...newAddresses[0],
                        healthcareLicense: newHealthcareLicense
                    };
                    handleNewAddresses(newAddresses);
                }
                if (isAddingAdditionalLocation) {
                    handleAdditionalAddress(newAddresses[0])
                }
                await dispatch(getSAPUserByEmail({ emailAddress: response?.data?.email })).unwrap();
                dispatch(setUserStatus(response?.data?.userStatus));
                setAddressInputFields(getDefaultAddressFields());
                onTabChange(tabIndex + 1);
            }
        } catch (error) {
            const defaultValidationErrorMsg = "We are unable to validate this address. Please re-enter or call 1-866-MODERNA (1-866-663-3762).";
            const errMsg = error?.response?.data?.errorType === "VALIDATION_ERROR"
                ? defaultValidationErrorMsg
                : error?.response?.data?.message || generalApiError;
            setErrorMsg(errMsg);
            if (errorMsgHandler) {
                errorMsgHandler(errMsg);
            } else {
                alertRef.current?.openAlert(errMsg)
            }
        } finally {
            userStatus === 'ACTIVE' ? setExternalProcessing(false) : setIsLoading(false);
            closeModal && closeModal()
        }
    };

    const validateField = (name, value) => {
        let errorMsg = "";

        const validators = {
            contactEmail: () => value && validateEmail(value),
            phoneNumber: () => value && validateShippingPhoneNumber(value),
            city: () => validateCity(value),
            zipcode: () => validateZipcode(value),
            tddd: () => addressInputFields.state === "OH" && validateTDDD(value),
            hin: () => value.trim() !== '' && validateHIN(value),
            dea: () => value.trim() !== '' && validateDEA(value),
            npi: () => value.trim() !== '' && validateNPI(value),
        };

        if (getRequiredFields().includes(name) && value.trim() === '') {
            errorMsg = validateRequiredField(value);
        } else if (validators[name]) {
            errorMsg = validators[name]();
        }

        return errorMsg || "";
    };

    const createTextField = (name, label, value, onChange, onBlur, isRequired, placeholder) => {
        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(name)}
                    error={Boolean(errors[name])}
                    helperText={errors[name]}
                    FormHelperTextProps={helperTextStyles}
                    inputProps={{
                        'data-testid': `${name}-input`
                    }}
                />
            </>
        )
    }

    const ShippingAddressForm = (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <form onSubmit={handleSubmit}>
                <MedicalLicenseFormModal 
                    isMedicalLicenseFormModalOpen={openLicenseFormModal}
                    handleCloseModal={handleCloseLicenseFormModal}
                    selectedState={addressInputFields.state}
                    setSelectedMedicalLicense={setSelectedMedicalLicense}
                    setAddressFormErrors={setErrors}
                    addressFormErrors={errors}
                />
                <MedicalLicenseSelectionModal 
                    isMedicalLicenseSelectionModalOpen={openLicenseSelectionModal}
                    handleCloseModal={handleCloseLicenseSelectionModal}
                    medicalLicensesForSelectedState={medicalLicensesForSelectedState}
                    setSelectedMedicalLicense={setSelectedMedicalLicense}
                    setOpenLicenseFormModal={setOpenLicenseFormModal}
                    setAddressFormErrors={setErrors}
                    addressFormErrors={errors}
                />
                <Container data-testid='onboardingShippingAndLicenseInfo'>
                    <Snackbar 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={3000}
                        open={isSnackbarOpen}
                        onClose={() => {setIsSnackbarOpen(false)}}
                    >
                        <SnackbarContent
                            message={getSnackbarMessage()}
                            sx={snackbarStyles}
                        />
                    </Snackbar>
                    {savedShippingAddresses?.length ? (
                        <Box data-testid='savedAddressesSection' display={'flex'} flexDirection={'column'} gap={'24px'} marginY={'28px'}>
                            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                <Typography fontSize={'20px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Saved Addresses</Typography>
                                {savedShippingAddresses.map((address, i) => {
                                    return (
                                        <Typography key={i} fontFamily={'Aeonik Regular'}>
                                            <span style={{ fontWeight: '700' }}>{address.dba},</span> 
                                            {` ${address.line1}${address.line2 ? ` ${address.line2}` : ''}, ${address.city}, ${address.state}`}
                                        </Typography>
                                    )
                                })}
                            </Box>
                        </Box>
                        ) : undefined
                    }
                    <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2}>
                        <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Shipping Address</Typography>
                        <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A"><RequiredSymbol>*</RequiredSymbol> Required field</Typography>
                    </Box>
                    <Grid container data-testid='facilityInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("dbaName", 'Facility "Doing business as" name', addressInputFields.dbaName, handleChange, handleBlur, true, 'e.g., Riverside Hospital, Greenleaf Community Pharmacy')}
                        </Grid>
                        <>
                            <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                                {createTextField("contactFirstName", "Facility contact first name", addressInputFields.contactFirstName, handleChange, handleBlur, true, 'e.g., Sarah')}
                            </Grid>
                            <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                                {createTextField("contactLastName", "Facility contact last name", addressInputFields.contactLastName, handleChange, handleBlur, true, 'e.g., Smith')}
                            </Grid> 
                        </> 
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("contactEmail", "Facility contact email", addressInputFields.contactEmail, handleChange, handleBlur, true, 'e.g., contact@email.com')}
                        </Grid>
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("phoneNumber", "Facility contact phone", addressInputFields.phoneNumber, handleChange, handleBlur, true, 'e.g., 123-456-7890')}
                        </Grid>
                    </Grid>
                    <Grid container data-testid='addressInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine1", "Address Line 1", addressInputFields.addressLine1, handleChange, handleBlur, true, 'e.g., 123 Main St')}
                            <Typography textAlign={'left'} fontFamily={'Aeonik Regular'} fontWeight={'400'} letterSpacing={'-0.28px'}>Note: Orders cannot be delivered to P.O. Boxes</Typography>
                        </Grid>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine2", "Suite Number", addressInputFields.addressLine2, handleChange, handleBlur, false, 'e.g., Dock 5, Ste 301')}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            {createTextField("city", "City", addressInputFields.city, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            <Dropdown
                                dataTestId={"stateDropdownSelection"}
                                name={"state"}
                                label={"State"}
                                options={USStates}
                                value={addressInputFields.state}
                                onChangeHandler={handleStateSelection}
                                isRequired={true}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcode", "ZIP Code", addressInputFields.zipcode, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcodeExt", "ZIP +4", addressInputFields.zipcodeExt, handleChange, handleBlur, false)}
                        </Grid>
                        {addressInputFields.state === 'OH' && 
                            <Grid item xs={12} paddingBottom={'1px'}>
                                {createTextField("tddd", "Terminal Distributer of Dangerous Drugs License (TDDD License)", addressInputFields.tddd, handleChange, handleBlur, true, 'Required for orders in the state of Ohio')}
                            </Grid>
                        }
                    </Grid>
                    {userStatus === 'ACTIVE' &&
                        <Box data-testid='medicalIdentificationSection'>
                            <Box marginBottom={'24px'}>
                                <Typography fontSize={'20px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Medical Identifiers</Typography>
                                <Typography fontSize={'14px'} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">
                                    We use these identifiers to locate and validate your contract pricing. Optional.
                                </Typography>
                            </Box>
                            <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                                <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                                    {createTextField("hin", "HIN", addressInputFields.hin, handleChange, handleBlur, false)}
                                </Grid>
                                <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                                    {createTextField("dea", "DEA Number", addressInputFields.dea, handleChange, handleBlur, false)}
                                </Grid>
                                <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                                    {createTextField("npi", "NPI", addressInputFields.npi, handleChange, handleBlur, false)}
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Box data-testid='medicalLicenseSection' display={'flex'} flexDirection={'column'} gap={'24px'} marginBottom={'48px'}>
                        <Box>
                            <Typography fontSize={'20px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Medical Licenses<RequiredSymbol>*</RequiredSymbol></Typography>
                            <Typography fontSize={'14px'} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">
                                Shipping address must be associated with a valid license before your order ships.
                            </Typography>
                        </Box>
                        {Object.keys(selectedMedicalLicense).length > 0 ? 
                            <MedicalLicenseCard selectedMedicalLicense={selectedMedicalLicense} removeLicense={removeLicense} /> :
                            <Box sx={buttonsContainerSxStyles}>
                                <Button 
                                    sx={{ padding: "16px 24px", textTransform: "none" }}
                                    buttonType="mds-primary"
                                    data-testid='addLicenseBtn'
                                    onClick={handleOnAddLicense}
                                >
                                    Add a medical license
                                </Button>
                            </Box>
                        }
                        {errors.medicalLicense && <Typography textAlign={'left'} color={'#E22929'} fontFamily={'Aeonik Regular'}>{errors.medicalLicense}</Typography>}
                    </Box>
                    <Box data-testid='buttonsSection' sx={buttonsContainerSxStyles}>
                        <Button 
                            type="submit"
                            id="submit"
                            sx={{ padding: "16px 24px", textTransform: "none" }}
                            buttonType="mds-primary"
                            data-testid='continueBtn'
                        >
                            Continue
                        </Button>
                        {userStatus !== 'ACTIVE' && 
                            <Button 
                                sx={{ padding: "16px 24px", textTransform: "none" }}
                                buttonType="mds-secondary"
                                data-testid='addAddressBtn'
                                onClick={handleAddAddress}
                            >
                                Save and add another address
                            </Button>
                        }
                        {isAddingAddress &&
                            <Button 
                                buttonType="link"
                                data-testid='cancelAddAddressBtn'
                                onClick={handleCancelAddAddress}
                            >
                                <Typography fontFamily={'Aeonik Regular'} fontSize={'14px'} letterSpacing={'-0.28px'} textTransform={'none'}>
                                    Discard and continue
                                </Typography>
                            </Button>
                        }
                    </Box>
                </Container>
            </form>
        </>
    )

    return (
        <Box
            data-testid="onboardingShippingAddressTab" 
            maxWidth={'700px'}
            display={'flex'}
            sx={{
                [theme.breakpoints.down('md')]: {
                    maxWidth: '600px',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '350px',
                },
            }}
        >
            {ShippingAddressForm}
        </Box>
    )
}

export default ShippingAddressForm