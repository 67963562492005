import { Box, CircularProgress, Typography } from '@mui/material';

const FullPageSpinner = ({ message }) => {
    return (
        <Box 
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                backgroundColor: '#F9F9FA',
                pointerEvents: 'none',
                flexDirection: 'column'
            }}
        >
            <Box sx={{ pointerEvents: 'all', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress />
                <Typography sx={{ paddingTop: '16px', fontSize: '24px', textAlign: 'center', fontWeight: 400, fontFamily: 'Aeonik Regular'}}>
                    {message}
                </Typography>
            </Box>
        </Box>
    );
}

export default FullPageSpinner;
