import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import CheckIcon from "../../../assets/images/check.svg";
import { Button } from '../../../components';
import { setCanAccessInvoicePayConfirmationPage } from "../../../features/globalStates/globalStatesSlice";
import { TypographyH1, TypographyH2, TypographyP1 } from "../../../assets/typographyTheme";
import { centsToUSD } from "../../../utils/Helper";

const InvoicePayConfirmationPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalStates.locale);
    const paymentDetails = useSelector((store) => store?.commerce?.paymentDetails?.paymentDetails);
    const canAccessInvoicePayConfirmationPage = useSelector((state) => state.globalStates.canAccessInvoicePayConfirmationPage)

    useEffect(() => {
        if(!canAccessInvoicePayConfirmationPage) {
            history?.push(`/${locale}/`);
        } else {
            dispatch(setCanAccessInvoicePayConfirmationPage(false));
        }
    }, [history]);

    const { name, email, invoiceId, amount, paymentMethodType, lastFour } = paymentDetails || {};

    return (
        <Container sx={{ minHeight: "550px", display: "flex", flexDirection:"column", alignItems: "center", paddingTop:"64px"}}>
            <img src={CheckIcon} alt='check icon' className='CheckIcon' height='56' width='56'/>
            <Box data-testid='ConfirmationMsgSection' display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Typography data-testid='ThankYouMsg' fontFamily="Aeonik Regular" fontSize="24px" fontWeight={500}>
                    Thank you, {name}.
                </Typography>
                <TypographyH1>Your payment for Invoice #{invoiceId} has been received.</TypographyH1>
            </Box>
            <Box width={'610px'} mt={6} mb={8}>
                <TypographyH2>Amount paid: {centsToUSD(amount)}</TypographyH2>
                <TypographyP1>
                {name} ({email})
                </TypographyP1>
                <TypographyP1>
                    {paymentMethodType === 'CARD' ? `Credit card ending in ${lastFour}` : `Bank account ending in ${lastFour}`}
                </TypographyP1>
            </Box>
            <Box mb={16} sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Button
                    buttonType='mds-secondary'
                    data-testid='placeAnotherInvoiceButton'
                    sx={{ padding: "16px 24px", textTransform: "none" }}
                    onClick={() => history.push(`/invoicepay/paynow`)}
                >
                    Pay another invoice
                </Button>
                <Button
                    buttonType='mds-secondary'
                    data-testid='goToHomePageButton'
                    sx={{ padding: "16px 24px", textTransform: "none" }}
                    onClick={() => history.push(`/${locale}/`)}
                >
                    Go to Homepage
                </Button>
            </Box>
        </Container>
    )
}

export default InvoicePayConfirmationPage;
