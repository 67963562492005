import { Box } from "@mui/material";
import { ContactInfoForm } from "./ContactInfoForm";
import { OrganizationForm } from "./OrganizationForm";
import { useSelector } from "react-redux";
import { USER_STATUS } from "../../../constants";

export const OrganizationTab = ({ tabIndex, onTabChange = () => {}, customerSegmentClasses }) => {
    
    const userStatus = useSelector((store) => store?.commerce?.userData?.userStatus) || '';
    
    return (
        <Box maxWidth={'700px'}>
            {userStatus === USER_STATUS.VERIFIED ? 
                <ContactInfoForm /> :
                <OrganizationForm
                    tabIndex={tabIndex}
                    onTabChange={onTabChange}
                    customerSegmentClasses={customerSegmentClasses}
                />
            }
        </Box>
    )
}