import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, Switch, useLocation, Link } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useTracking } from '@modernatx/tracking';
import NewHomePage from "./pages/NewHomepage";
import GlobalStyle from "./global/GlobalStyle";
import { TextInput, ExternalRouterPrompt, Button, Spinner } from "../src/components";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl, urlParamsToObject, isRedirectUri } from "@okta/okta-auth-js";
import { oidcConfig } from "./config";
import { IdxTransaction, UserInfoContext } from './contexts';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { fetchEmpUserApi } from "./utils/commerceEmpUsersApi";
import { loadInterstitialComponentStaticContent, loadLocaleISOCodeOptions } from "./features/contentful/contentfulThunkApi";
import { setLocale, setLocaleISOCode } from "./features/globalStates/globalStatesSlice";
import { getBaseStoreDataApi, fetchUserApi } from "./features/commerce/commerceThunkApi";
import { clearContentfulStore } from "./features/contentful/contentfulSlice";
import { clearGlobalMessageStore } from "./features/globalMessages/globalMessageSlice";
import { Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import { styled } from '@mui/material/styles';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { contentfulClient } from "./sevices/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import { loadGlobalMessage } from "./features/globalMessages/globalMessageThunkApi";
import Locale from "./Locale";
import LoginCallback from "./components/LoginCallback";
import HomePage from "./pages/Homepage";
import { ModalProvider } from "./providers/ModalProvider";
import { USER_STATUS } from "./constants";
import FallbackErrorPage from "./pages/FallbackErrorPage";

//remove later
const GatedDiv = styled('div')(({ theme: { breakpoints } }) => `
  display: grid;
  margin: 200px auto;
  align-content: center;
  width: 30%;
  ${[breakpoints.down('sm')]} {
    box-sizing: border-box;
    width: 80%;
  }
`);

const isSapEnabled = process.env.IS_SAP_ENABLED === "true";

const Container = styled('div')(
  ({ isAdmin , sessionHeaderOpen, theme: { breakpoints } }) => `
    ${[breakpoints.down('sm')]} {
      padding-top: ${localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined" && localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" ? (sessionHeaderOpen ? "287px" : "153px") : (isAdmin ? "0" : "80px")};
    }
  `
);

const oktaAuth = (() => {
  const { state } = urlParamsToObject(window.location.search);
  return new OktaAuth(
    Object.assign({}, oidcConfig, {
      state,
    })
  );
})();

const App = () => {
  const [appInitialized, setAppInitialized] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const relativePathname = location?.pathname;
  const [processing, setProcessing] = useState(false);
  const { interstitialComponentContent: staticContent  } = useSelector((store) => store?.contentful);
  const { baseStoreData } = useSelector((store) => store?.commerce);
  const dispatch = useDispatch()
  const [transaction, setTransaction] = useState(null);
  const [gated, setGated] = useState(true); //remove later
  const [password, setPassword] = useState(""); //remove later
  const [cookies, setCookie] = useCookies(['gated']);
  const [cutOffDaysRemaining, setCutOffDaysRemaining] = useState()
  const [showNavigationAlert, setShowNavigationAlert] = useState(false);
  const [externalLink, setExternalLink] = useState("");
  const [externalLinkModalType, setExternalLinkModalType] = useState("socialLink");
  const sessionHeaderOpen = useSelector((state) => state.globalStates.sessionHeaderOpen)
  const [globalErrorMessage, setGlobalErrorMessage] = useState(null);
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState(null);
  const { localeISOCodeOptions: localeISOCodes } = useSelector((store) => store?.contentful);
  const locale = useSelector((state) => state.globalStates.locale);
  const userStatus = useSelector((state) => state.globalStates.userStatus);
  const captchaSiteKey = process.env.CAPTCHA_SITE_KEY;
  const globalGatingEnvVar = process.env.GATING_PAGE; // env variable to show/hide the gating page based on environment.
  const externalLinkModalHeading = "Leaving Moderna Direct";
  const externalLinkModalContent = "You are now leaving Moderna Direct. The intended use and audience for the linked website may differ.";
  const externalLinkModalCheckBoxLabel = "Don't remind me again";
  const externalLinkModalButtonLabel = "Continue";

  const isNewHomepageEnabled = process.env.IS_NEW_HOMEPAGE_ENABLED === 'true' && locale === 'en-US';
  const { trackError } = useTracking();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const email = _oktaAuth?.authStateManager?.['_authState']?.idToken?.claims?.email;
    try {
      setProcessing(true);
      const empUser = await fetchEmpUserApi(email);
      if (!empUser?.data) {
        history.push("/not-authorized") // take user to 403 page
        return;
      }
      history.replace(toRelativeUrl(`/${locale}/admin`, window.location.origin)); // take user to /admin
      localStorage.setItem("employeeUser", JSON.stringify(empUser?.data));
      setProcessing(false);
    } catch (err) {
      localStorage.setItem("invalidUser", true);
      await oktaAuth.signOut();
      setProcessing(false);
    }
  };

  const onAuthRequired = () => {
    history.push(`/${locale}/`);
  };

  const getUser = () => {
    const user = localStorage.getItem("currentUser")
    if (user !== "undefined")
      return JSON.parse(user)
    else
      return null
  }

  const [userInfo, setUserInfo] = useState(getUser()); //leave it here
  useEffect(() => {
    history.listen(location => {
      localStorage.setItem("isRouteChanged", true);
      window.scrollTo(0, 0)
    });
  }, [history]); 

  useEffect(()=>{
    // Initial load of the app, clear the contentful store
    dispatch(clearContentfulStore());
    dispatch(clearGlobalMessageStore());

    if (staticContent === null || Object.keys(staticContent).length === 0) {
      dispatch(loadInterstitialComponentStaticContent())
    }
    dispatch(loadGlobalMessage());
    dispatch(loadLocaleISOCodeOptions());

    if(( !isCurrentUserAvailable() && !window.location.href.includes('login/callback') ) || isImpersonationEnabled()) {
      if(window.location.href.includes('en-DE')) {
        dispatch(setLocale('en-DE'));
        dispatch(setLocaleISOCode('DE'));
      } else {
        dispatch(setLocale('en-US'));
        dispatch(setLocaleISOCode('US'));
      }
    }

    if (localStorage.getItem("okta-token-storage") === "{}") {
      localStorage.removeItem("currentUser")
    }

    if (oktaAuth.isLoginRedirect()) {
      handleRedirect();
    }

    setAppInitialized(true);
  }, []);

  // Force onboarding users to stay on the page they are allowed access to
  useEffect(() => {
    if (userStatus !== USER_STATUS.ACTIVE) {
      switch (userStatus) {
        case USER_STATUS.VERIFIED:
          history.push(`/onboarding/contact-info`);
        break;
        case USER_STATUS.ONBOARDING_ORG_COMPLETE:
          history.push(`/onboarding/shipping-address`);
        break;
        case USER_STATUS.ONBOARDING_SHIP_COMPLETE:
          history.push(`/onboarding/billing-address`);
        break;
        case USER_STATUS.ONBOARDING_BILL_COMPLETE:
          history.push(`/onboarding/medical-identification`);
        break;
        default:
          localStorage.removeItem("currentUser");
      }
    }
  }, [userStatus]);
  
  useEffect(()=>{
    let newUrl = ''
    if(locale === 'en-US' && window.location.href.includes('en-US')){
      newUrl = window.location.href.replace(/\/en-US/, '');   
    } else if(locale === 'en-US' && window.location.href.includes('en-DE') && isCurrentUserAvailable()){
      newUrl = window.location.href.replace(/\/en-DE/, '');
    } else if(locale === 'en-DE' && window.location.href.includes('en-US') && isCurrentUserAvailable()){
      newUrl = window.location.href.replace(/\/en-US/, '/en-DE');   
    }
    window.history.replaceState({}, '', newUrl);
  }, [history.location.pathname, locale]);

  useEffect(() => {
    const checkCutOffDate = async () => {
      try {
        const cutOffDate = baseStoreData?.cutoffDate
        const diffTime = new Date(cutOffDate) - new Date()
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        setCutOffDaysRemaining(diffDays)
      } catch (error) {
        console.log("error ", error)
      }
    }
    checkCutOffDate()
  }, [baseStoreData])

  useEffect(() => {
    if (isSapEnabled) {
      dispatch(getBaseStoreDataApi({baseSiteId: locale === 'en-DE' ? 'modernaDirect-DE' : 'modernaDirect-US'}))
    }
  }, [dispatch, locale])

  const handleRedirect = async () => {
    await oktaAuth.handleLoginRedirect();
  }

  const handleGermanyGating = useCallback(async () => {
      setProcessing(true);
      const params = window.location.search.replace(/^\?/, '')
      const singledecode = decodeURIComponent(params);
      const doubledecode = decodeURIComponent(singledecode);
      const urlParams = new URLSearchParams(doubledecode);
      const tokenStr = urlParams.get('tokens');
      const token = JSON.parse(tokenStr);

      // Add tokens to Okta's SDK, should be logged after this
      oktaAuth.tokenManager.add('accessToken', token.accessToken);
      oktaAuth.tokenManager.add('idToken', token.idToken);
      const oktaUserDetails = await oktaAuth.getUser();
      //call commerce getUser API 
      
      dispatch(fetchUserApi({ userId: oktaUserDetails?.sub }))
      .unwrap()
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user?.data))
        setUserInfo(user.data);
        if(user.data.country.isocode==="DE"){
          if (isSapEnabled) {
            dispatch(getBaseStoreDataApi({baseSiteId: 'modernaDirect-DE'}))
          }
          dispatch(setLocale('en-DE'));
          dispatch(setLocaleISOCode('DE'));
        }
        setProcessing(false);
        history.push(`/${locale}/home`)
      })
      .catch(async (error) => {
        await oktaAuth.signOut();
        setProcessing(false);
        history.push(`/${locale}/not-authorized`)
      })

  }, []); 
  
  useEffect(() => {
    if(window.location.pathname.includes("/login/callback") && window.location.search.includes("tokens=")){
      handleGermanyGating();
    }
  }, [handleGermanyGating])

  useEffect(() => {
    //remove later
    if (cookies?.gated) {
      setGated(false);
    } else {
      setGated(true);
    }

    const resumeTransaction = async () => {
      const newTransaction = await oktaAuth.idx.proceed();
      setTransaction(newTransaction);
    };

    if (
      !isRedirectUri(window.location.href, oktaAuth) &&
      oktaAuth.idx.canProceed()
    ) {
      resumeTransaction();
    }
  }, [cookies]);

  //remove later
  const handleGated = () => {
    if (password && password === "ModernaDirect22") {
      let tomorrow = new Date(), today = new Date();
      tomorrow.setDate(today.getDate() + 1);
      setCookie('gated', false, { path: "/", expires: tomorrow });
      setGated(false);
    } else {
      setGated(true);
    }
  };

  const closeNavigationAlert = (type) => {
    setShowNavigationAlert(false)
  }

  const messageOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (_, children) => <Link to="/privacy-policy" target="_blank">{children}</Link>,
    },
  };

  const isCurrentUserAvailable = () => localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== "undefined";
  const isEmployeeLoggedIn = () => localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" && !isCurrentUserAvailable();
  const isImpersonationEnabled = () => isCurrentUserAvailable() && localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined";
  const isAdminUrl = () => window.location.pathname ===`/${locale}/admin` || window.location.pathname === '/admin';
  const isOnboarding = () => {
    const basePath = window.location.pathname;
    const isEmailVerificationOrConfirmation = basePath === `/${locale}/registration/email-verification` || 
                                               basePath === `/registration/email-verification` || 
                                               basePath === `/${locale}/registration/email-confirmation` || 
                                               basePath === `/registration/email-confirmation`;
  
    const isOnboardingPath = basePath === `/${locale}/onboarding` || 
                             basePath === '/onboarding' || 
                             basePath.includes('/onboarding');
  
    return isEmailVerificationOrConfirmation || (isCurrentUserAvailable() && isOnboardingPath);
  };

  const getGlobalMessages = async (type) => {
    const query = {
      skip: 0,
      content_type: type === "error" ? "globalErrorMessage" : "globalSuccessMessage",
      include: 5,
      select: "fields",
    };
    contentfulClient.getEntries(query).then((res) => {
      if(type === "error"){
        setGlobalErrorMessage(res?.items);
        localStorage.setItem("globalErrorMessage", JSON.stringify(res?.items));
      }else{
        setGlobalSuccessMessage(res?.items);
        localStorage.setItem("globalSuccessMessage", JSON.stringify(res?.items));
      }
    });
  };

  useEffect(() => {
    try {
      if(!globalSuccessMessage){
        const globalStoredSuccessMessage = JSON.parse(localStorage.getItem("globalSuccessMessage"));
        globalStoredSuccessMessage ? setGlobalSuccessMessage(globalStoredSuccessMessage) : getGlobalMessages("success");
      }

      if(!globalErrorMessage){
        const globalStoredErrorMessage = JSON.parse(localStorage.getItem("globalErrorMessage"));
        globalStoredErrorMessage ? setGlobalErrorMessage(globalStoredErrorMessage) : getGlobalMessages("error");
      }
    } catch(err) {
      console.log(err?.message);
    }
  }, [globalErrorMessage, globalSuccessMessage]);

  const handleErrorBoundaryError = () => {
    // Reports to Dynatrace
    reportError("User encountered error boundary and was shown the fallback page.");
    // Reports to Amplitude
    trackError("User encountered error boundary and was shown the fallback page.")
  }

  return appInitialized ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={captchaSiteKey}
      useEnterprise={true}
    >
      <div>
        {
          //remove later
          (globalGatingEnvVar === "true" && gated && (oktaAuth?.authStateManager?.['_authState']?.['isAuthenticated'] === false)) ? (
            <GatedDiv>
              <Typography variant={'p2'} textAlign="center">
                Enter the password that has been shared with you
              </Typography>
              <TextInput
                sx={{margin:'0 auto !important', width:'60%'}}
                id="password"
                inputType="text"
                label="password"
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                buttonType="primary"
                icon={false}
                onClick={handleGated}
                sx={{margin: '20px auto !important'}}
              >
                Enter
              </Button>
            </GatedDiv>
          ) : (
            <div>
              <Security
                oktaAuth={oktaAuth}
                restoreOriginalUri={restoreOriginalUri}
                onAuthRequired={onAuthRequired}>

                <Spinner processing={processing} />
                <IdxTransaction.Provider value={{ transaction, setTransaction }}>
                  <UserInfoContext.Provider value={{ userInfo, setUserInfo, cutOffDaysRemaining }}>
                  <ErrorBoundary FallbackComponent={FallbackErrorPage} onReset={() => history.push('/home')} onError={handleErrorBoundaryError}>
                    <GlobalStyle />
                    {
                      externalLinkModalType === "extrenalLink" && showNavigationAlert && !localStorage.getItem('dontRemindMeExternal') && <ExternalRouterPrompt
                        isOpen={showNavigationAlert}
                        closeModalHandler={() => closeNavigationAlert("extrenalLink")}
                        externalLink={externalLink}
                        onOpenLink={setShowNavigationAlert}
                        type={"extrenalLink"}
                        heading= {externalLinkModalHeading}
                        headingStyle={{
                          fontFamily: "Aeonik Bold",
                          fontSize: "30px",
                          '@media screen and (max-width: 500px)': {
                            fontSize: '24px',
                            paddingBottom: "24px"
                          }
                        }}
                        content= {externalLinkModalContent}
                        checkBoxLabel= {externalLinkModalCheckBoxLabel}
                        buttonLabel= {externalLinkModalButtonLabel}
                      />
                    }

                    {
                      externalLinkModalType === "socialLink" && showNavigationAlert && !localStorage.getItem('dontRemindMe') &&
                      <ExternalRouterPrompt
                        isOpen={showNavigationAlert}
                        closeModalHandler={() => setShowNavigationAlert(false)}
                        externalLink={externalLink}
                        onOpenLink={setShowNavigationAlert}
                        heading={
                          <>
                            {staticContent?.socialLink?.header} <h1 style={{ fontFamily: "Aeonik Bold" }}>{staticContent?.socialLink?.subHeader}</h1>
                          </>
                        }
                        modalCustomStyle={{
                          borderLeft: "24px solid #BFE6F6",
                          '@media screen and (max-width: 500px)': {
                            borderLeft: 'unset',
                            borderTop: "24px solid #BFE6F6",
                            padding: "16px 16px 32px 32px"
                          }
                        }}
                        headingStyle={{
                          fontFamily: "Aeonik Light",
                          fontSize: "40px",
                          '@media screen and (max-width: 500px)': {
                            fontSize: '24px',
                            paddingBottom: "24px"
                          }
                        }}
                        content={
                          <>
                            {documentToReactComponents(staticContent?.socialLink?.message, messageOptions)}
                          </>
                        }
                        buttonLabel={staticContent?.socialLink?.buttonLabel}
                        checkBoxLabel={staticContent?.socialLink?.remindMeText}
                      />}
                    <ModalProvider>
                      {!isAdminUrl() && !isOnboarding() && <Header baseStoreData={baseStoreData} setProcessing={setProcessing}/>}
                    </ModalProvider>
                    <Container isAdmin={isAdminUrl()} sessionHeaderOpen={sessionHeaderOpen}>
                      <Switch>
                      <Route exact={true} path="/">
                        {isNewHomepageEnabled ? (
                          <ModalProvider>
                            <NewHomePage />
                          </ModalProvider>
                        ) : (
                          <HomePage />
                        )}
                      </Route>
                        <Route exact={true} path="/login/callback" component={LoginCallback} />
                        <Route path="/:locale">
                          <Locale relativePathname={relativePathname} locale={locale} setExternalLink={setExternalLink} setShowNavigationAlert={setShowNavigationAlert} setExternalLinkModalType={setExternalLinkModalType} staticContent={staticContent} isEmployeeLoggedIn={isEmployeeLoggedIn} isImpersonationEnabled={isImpersonationEnabled} isCurrentUserAvailable={isCurrentUserAvailable} />
                        </Route>
                      </Switch>
                    </Container>
                    {!isAdminUrl() && !isOnboarding() && <Footer setShowNavigationAlert={setShowNavigationAlert} setExternalLink={setExternalLink} setExternalLinkModalType={setExternalLinkModalType} />}
                    </ErrorBoundary>
                  </UserInfoContext.Provider>
                </IdxTransaction.Provider>
              </Security>
            </div>
          )
        }
      </div>
    </GoogleReCaptchaProvider>
  ) : (
      <Spinner processing={!appInitialized} />
  );
};

export default App;
