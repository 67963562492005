export const PaymentErrorCodes = {
    CREATE: {
        RESOURCE_QUOTA_EXCEEDED: 'RESOURCE_QUOTA_EXCEEDED_ERROR',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    LIST: {
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    UPDATE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    DESCRIBE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    DELETE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
    }
};

export const CapturePaymentErrorCodes = {
    CAPTURE: {
        GENERIC_DECLINE: 'GENERIC_DECLINE',
        FRAUDULENT: 'FRAUDULENT',
        CARD_DECLINED: 'CARD_DECLINED',
        EXPIRED_CARD: 'EXPIRED_CARD',
        CAPTURE_FAILURE: 'CAPTURE_FAILURE',
        BALANCE_DATA_UNAVAILABLE: 'BALANCE_DATA_UNAVAILABLE',
        INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS'
    }
}
