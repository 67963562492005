import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLocale, setLocaleISOCode, setCanOrder2023Products, setUserStatus } from '../features/globalStates/globalStatesSlice';
import { fetchUserApi, getBaseStoreDataApi } from '../features/commerce/commerceThunkApi';
import { useOktaAuth } from '@okta/okta-react';
import { IdxStatus } from '@okta/okta-auth-js';
import { getSAPUserByEmail, getUserByEmail } from '../utils/UserService/UserApis';
import { registerExistingUser } from '../utils/Registration/RegistrationAPI';
import { validateFields } from '../utils/Helper';
import { useIdxTransaction, useUserInfoContext } from '../contexts';
import { useModal } from '../hooks/useModal';
import { useTracking } from '@modernatx/tracking';
import { MODERNA_INTERNAL_DOMAIN, USER_STATUS } from '../constants';

const isSapEnabled = process.env.IS_SAP_ENABLED === "true";

export const useAuth = ({
  setLoading,
  setInvalidEmail,
  setInvalidPassword,
  setProcessing,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { oktaAuth } = useOktaAuth();
	const { setTransaction } = useIdxTransaction();
	const { setUserInfo } = useUserInfoContext() || '';
	const { handleCloseModal } = useModal();
	const {
		trackCustom,
		trackError
	} = useTracking();

	const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
	const invalidEmailFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_EMAIL_FORMAT");
	const invalidEmailFormatError = invalidEmailFormatErrorData && invalidEmailFormatErrorData[0]?.fields?.message;

	const invalidCredentialsErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_CREDENTIALS");
	const invalidCredentialsError = invalidCredentialsErrorData && invalidCredentialsErrorData[0]?.fields?.message;

	const accountLockedErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "ACCOUNT_LOCKED");
	const accountLockedError = accountLockedErrorData && accountLockedErrorData[0]?.fields?.message;

	const closeLoginModal = () => {
		handleCloseModal();
	};

	const validate = useCallback((email, password) => {
		let isValid = true;
		let errors = { email: '', password: '' };

		if (!email) {
		isValid = false;
		errors.email = "Please enter your email address.";
		} else if (!validateFields("email", email)) {
		isValid = false;
		errors.email = invalidEmailFormatError;
		} else if (email?.toLowerCase().includes(MODERNA_INTERNAL_DOMAIN)) {
			isValid = false;
			errors.email = "Email addresses containing the modernatx domain are not allowed.";
		}

		if (!password) {
		isValid = false;
		errors.password = "Please enter your password.";
		}

		return { isValid, errors };
	}, []);

	const signIn = useCallback(async (email, password, onSuccess, postLoginRedirectPath) => {
		const { isValid, errors } = validate(email, password);
		if (!isValid) {
			setInvalidEmail(errors.email);
			setInvalidPassword(errors.password);
			return;
		}
		manageLocalStorage.clearItems();
		try {
			setProcessing(true);
			let newTransaction = await oktaAuth.idx.authenticate();
			setTransaction(newTransaction);
			if (newTransaction.status === IdxStatus.SUCCESS) {
				setProcessing(false);
				oktaAuth.tokenManager.setTokens(newTransaction.tokens);
				await loadData(postLoginRedirectPath);
				onSuccess && onSuccess();
				closeLoginModal();
			} else {
				const newTransaction = await oktaAuth.idx.proceed({ username: email, password: password });
				const { status, tokens, messages } = newTransaction;
				if (status === IdxStatus.SUCCESS) {
					setProcessing(false);
					oktaAuth.tokenManager.setTokens(tokens);
					await loadData(postLoginRedirectPath);
					onSuccess && onSuccess();
					closeLoginModal();
				} else if (status === IdxStatus.PENDING) {
					if (localStorage.getItem('awaitingEmailVerification')) {
						history.push(`/registration/email-verification`);
						closeLoginModal();
					}
					setProcessing(false);
					sessionStorage.removeItem("okta-transaction-storage");
					if(messages){
						if (messages[0]?.i18n?.key === "errors.E0000004") {
							setInvalidPassword(`Invalid email or password. Please check your credentials and try again.`);
						}
						else if(messages[0]?.i18n?.key === "errors.E0000119"){
							setInvalidPassword(accountLockedError);
						}
						else {
							setInvalidPassword(messages[0].message);
						}
					}
					else if (newTransaction?.nextStep?.name === "reenroll-authenticator" &&
						newTransaction?.nextStep?.type === "password") {
						setInvalidPassword(`Your password has expired. Click 'Reset your password' to update it.`);
					}
					else if (!newTransaction.status.tokens){
						setTimeout(() => {
							window.location.href = newTransaction?.nextStep?.href;
						}, 100);
					}
					else{
						setInvalidPassword(`We've encountered an issue with your account, please refresh the page. If the problem persists, contact customer support.`);
					}
				} else if (status === IdxStatus.TERMINAL) {
					setProcessing(false);
					sessionStorage.removeItem("okta-transaction-storage");
					setInvalidPassword(messages[0].message);
				} else {
					setProcessing(false);
					sessionStorage.removeItem("okta-transaction-storage");
					setInvalidPassword(invalidCredentialsError);
				}
			}
		} catch (err) {
			setProcessing(false);
			setInvalidPassword(err);
		}
	}, [dispatch, oktaAuth, setTransaction, setUserInfo, history, trackError, setLoading, validate, setInvalidPassword, setProcessing]);

	const fetchUserDetails = async (emailAddress, oktaID) => {
			try {
				const userByEmailResponse = await dispatch(getUserByEmail({ emailAddress })).unwrap();
				const sapUserApiResponse = await dispatch(getSAPUserByEmail({ emailAddress })).unwrap();
				return userByEmailResponse;
			} catch (error) {
				if (error?.status === 404) {
					const response = await registerExistingUser({ emailAddress: emailAddress });
					if (response?.status === 200) {
						await dispatch(getSAPUserByEmail({ emailAddress })).unwrap();
						return await dispatch(getUserByEmail({ emailAddress })).unwrap();
					}
				} else {
					trackCustom("Error While Migrating SAP User", { "OktaID": oktaID }); //REMOVE THIS?
				}
			}
	}

	const manageLocalStorage = {
		setItem: (key, value) => localStorage.setItem(key, value),
		clearItems: () => {
		  localStorage.clear();
		  sessionStorage.clear();
		},
		setInvalidUser: () => {
		  manageLocalStorage.clearItems();
		  localStorage.setItem("invalidUser", true);
		},
	};

	const processUserStatus = async (user, history, redirectPath) => {
		switch (user?.userStatus) {
			case USER_STATUS.UNVERIFIED:
				manageLocalStorage.setItem('awaitingEmailVerification', 'true');
				history.push(`/registration/email-verification`);
			break;
			case USER_STATUS.VERIFIED:
				manageLocalStorage.setItem('awaitingEmailVerification', 'false');
				history.push(`/onboarding/contact-info`);
			break;
			case USER_STATUS.ONBOARDING_ORG_COMPLETE:
				history.push(`/onboarding/shipping-address`);
			break;
			case USER_STATUS.ONBOARDING_SHIP_COMPLETE:
				history.push(`/onboarding/billing-address`);
			break;
			case USER_STATUS.ONBOARDING_BILL_COMPLETE:
				history.push(`/onboarding/medical-identification`);
			break;
			case USER_STATUS.ACTIVE:
				history.push(redirectPath || `/home`);
			break;
			default:
				manageLocalStorage.setInvalidUser();
				await oktaAuth.signOut();
		}
	};
	
	const loadData = useCallback(async (postLoginRedirectPath) => {
		setProcessing(true);
		try {
			const userDetails = await oktaAuth.getUser();
			let user = null;
			user = await fetchUserDetails(userDetails?.email, userDetails?.sub);
			dispatch(setUserStatus(user?.userStatus));
			await processUserStatus(user, history, postLoginRedirectPath);

			if (isSapEnabled) {
				// Always make a call to SAP. This is to support 2023 ordering and other SAP offerings
				try {
					const userApiResponse = await dispatch(fetchUserApi({ userId: userDetails?.sub })).unwrap();
					user = userApiResponse?.data;
					dispatch(setCanOrder2023Products(true));
				} 
				catch (error) {
					// Checking for 403 because of how we're handling authentication via /v2-new-auth. 
					if (error?.status === 403) {
						trackCustom("User Not In SAP", { "OktaID": userDetails?.sub })
						dispatch(setCanOrder2023Products(false));
					}
				}
			}

			localStorage.setItem("currentUser", JSON.stringify(user));
			setUserInfo(user);
			const isocode = user.country?.isocode || 'US';
			const locale = isocode === "DE" ? 'en-DE' : 'en-US';
			const baseSiteId = isocode === "DE" ? 'modernaDirect-DE' : 'modernaDirect-US';
			dispatch(setLocale(locale));
			dispatch(setLocaleISOCode(isocode));
			if (isSapEnabled) { dispatch(getBaseStoreDataApi({ baseSiteId })); }
		
		} catch (error) {
			manageLocalStorage.setInvalidUser();
			await oktaAuth.signOut();
			setInvalidPassword(error); 
		} finally {
			setProcessing(false);
		}
  	}, [dispatch, oktaAuth, setUserInfo, getUserByEmail, trackCustom]);
  	
	return { signIn, loadData };
};
