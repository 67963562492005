import { useHistory } from 'react-router-dom';
import CardComponent from '../CardComponent';

export const InvoicePayCard = () => {
    const history = useHistory();

    return (
        <CardComponent
            title={"Pay an invoice"}
            actions={[
                {   label: 'Pay now',
                    onClick: () => {history.push(`/invoicepay`) },
                    sx: { color: '#01283C', fontSize: '16px', fontWeight: '700', fontFamily: 'Aeonik', lineHeight: '24px'}
                }]}
            sx={{
                width: '345px',
            }}
        />
    );
};
