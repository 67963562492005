import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Divider, Typography } from "@mui/material";
import AddressBlock from "./AddressBlock"
import CustomCard from "../CustomCardComponent/CustomCard";
import ProductTable from "./ProductTable";
import ProductDetailModal from "./ProductDetailModal";
import AddressSelectionModal from './AddressSelectionModal';
import { loadReservationPage } from "../../features/contentful/contentfulThunkApi";
import { formattedPrice } from '../../utils/Helper';
import { setReservationData } from '../../features/commerce/commerceSlice';
import {UserAddressFormModal} from "../AddressesForm/UserAddressForm";

const ReservationDetailsCard = ({entries: reservationEntries, handleDosesChange, deleteAddressRow, updateDosesQty, setExternalProcessing, errorMsgHandler }) => {
    const isRealTimePricingEnabled = process.env.IS_REAL_TIME_PRICING_ENABLED === 'true';
    const dispatch = useDispatch();
    const { reservationPage: reservationPageContent } = useSelector((store) => store?.contentful);
    const { reservationData } = useSelector((store) => store?.commerce);
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const [openProductModal, setOpenProductModal] = useState(false);
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [showUserAddressFormModal, setShowUserAddressFormModal] = useState(false);

    const closeProductModal = () => {
        setOpenProductModal(false)
    }

    const closeAddressModal = () => {
        setOpenAddressModal(false)
    }

    useEffect(() => {
        if (reservationPageContent && Object.keys(reservationPageContent).length === 0){
            dispatch(loadReservationPage());
        }
    }, [reservationPageContent, dispatch]);

    const handleAddNewAddress = () => {
        setOpenAddressModal(false);
        setShowUserAddressFormModal(true);
    }

    const updateEntriesOnNewAddress = (newAddresses) => {
        let currentReservationEntryIds = new Set(reservationEntries.map(entry => entry.id));
        if (Array.isArray(newAddresses) && newAddresses.length > 0) {
            const updatedReservationEntries = reservationData.reservationEntries.map((entry) => {
                if (currentReservationEntryIds.has(entry.reservationEntryId)) {
                    return {...entry, deliveryAddressId: newAddresses[0].id};
                }
                return entry;
            });

            dispatch(setReservationData({reservationEntries: updatedReservationEntries}));
        }
    }

    let totalDoses = 0;
    let totalPrice = 0;

    if (isAuthenticated) {
        const result = reservationEntries.reduce(({totalDoses, totalPrice}, product) => {
            const pricePerDose = (isRealTimePricingEnabled && product.contractPriceValue !== undefined) ? product.contractPriceValue : product.listPriceValue;
            return {
                totalDoses: totalDoses + product.doses,
                totalPrice: totalPrice + product.doses * pricePerDose
            };
        }, {totalDoses: 0, totalPrice: 0});

        totalDoses = result.totalDoses;
        totalPrice = result.totalPrice;
        updateDosesQty(totalDoses);
    }

    const displayAddress = () => {
        const address = isAuthenticated ? reservationEntries[0]?.address : null;
        return address
    };

    return (
        <>
            <ProductDetailModal openModal={openProductModal} closeModal={closeProductModal}/>
            {openAddressModal && <AddressSelectionModal openModal={openAddressModal}
                                   closeModal={closeAddressModal}
                                   currentAddress={displayAddress()}
                                   handleAddNewAddress={handleAddNewAddress}
                                   isOrdering={false}
            />
            }
            {showUserAddressFormModal &&
                <UserAddressFormModal
                    openModal={showUserAddressFormModal}
                    closeModal={() => setShowUserAddressFormModal(false)}
                    handleNewAddresses={updateEntriesOnNewAddress}
                    addressType={"ship_to"}
                    setExternalProcessing={setExternalProcessing}
                    errorMsgHandler={errorMsgHandler}
                />
            }
            <Box flexGrow={1} minHeight={'412px'} mt={5} mb={5}>
                <CustomCard
                    sx={{padding: "32px"}}
                >
                    <Box display="flex" flexGrow={1} flexDirection="column" alignContent={'space-between'}>
                        <Box display="flex" flexGrow={1} flexWrap={'wrap'} mb={3} gap={8} minHeight={'240px'}>
                            <Box minWidth={'240px'}>
                                <AddressBlock shipToAddress={displayAddress()} setOpenAddressModal={setOpenAddressModal} deleteAddressRow={deleteAddressRow} handleAddNewAddress={() => setShowNewAddressFormModal(true)}/>
                            </Box>
                            <Box flex={1} width={'65%'}>
                                <ProductTable 
                                    products={reservationEntries} 
                                    isAuthenticated={isAuthenticated}
                                    handleDosesChange={handleDosesChange}
                                    setOpenModal={setOpenProductModal}
                                />
                                <Box minHeight={'72px'} mt={2}>
                                    <Divider orientation="horizontal" variant="middle" />
                                    <Box display="flex" flexDirection="row"  justifyContent="space-between" flexWrap={'wrap'} mt={2}>
                                        <Box>
                                            <Typography style={{fontSize:'14px', fontWeight:'400', lineHeight:'20px', whiteSpace: "pre-line"}}>{reservationPageContent?.dosesMultipleOf10Text?.content[0]?.content[0]?.value}</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '48px'}}>
                                            <Typography style={{fontSize:'16px', fontWeight:'700', lineHeight:'22px'}}>{isAuthenticated ? `Total Doses: ${totalDoses}` : `Total Doses: 0`}</Typography>
                                            <Typography style={{fontSize:'16px', fontWeight:'400', lineHeight:'28px'}}>{isAuthenticated && `Total Price: ${formattedPrice(totalPrice)}`} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CustomCard>
            </Box>
        </>
    )
}
export default ReservationDetailsCard
