import * as PaymentErrors from './PaymentsService/errorMessages';
import * as OrderErrors from './OrderService/errorMessages';

const errorMessages = {
  ...PaymentErrors.PaymentErrorMessages,
  ...OrderErrors.OrderErrorMessages,
};

const DEFAULT_ERROR_MESSAGE = 'An error occurred, the issue may be temporary. Please refresh the page and try again. If the issue persists, please contact us at 1-866-MODERNA.';

export const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || DEFAULT_ERROR_MESSAGE;
};

export const getCapturePaymentErrorMessages = (errorCode) => {
  return PaymentErrors.PaymentErrorMessages[errorCode] || DEFAULT_ERROR_MESSAGE;
};
