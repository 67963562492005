import { useMemo } from 'react';
import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formattedPrice } from '../../utils/Helper';
import {formatAddresses} from "../../utils/Reservation/reservationCommons";
import { UNAVAILABLE } from '../../constants';

const TypographyHeading = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding-left: 10px;
`);

const TypographyText = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 10px;
`);

const TypographySummaryText = styled(Typography)(({ theme: { breakpoints } }) => `
    color: var(--primary-dark, #363D4A);
    text-align: right;
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.32px;
    text-align: center;
`);

const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
   .MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
     text-transform: none;
   }
`

const columns = [
    {
        field: 'title',
        headerName: 'Item',
        flex: 1,
        minWidth: 100,
        sortable: false,
    },
    {
        field: 'doses',
        headerName: 'Doses',
        headerAlign: 'center',
        align: 'center',
        width: 120,
        sortable: false,
    },
    {
        field: 'price',
        headerName: 'List Price',
        headerAlign: 'center',
        width: 130,
        align: 'center',
        sortable: false,

    },
    {
        field: 'contractPrice',
        headerName: 'Contract Price*',
        width: 140,
        headerAlign: 'center',
        align: 'center',
        sortable: false,

    },
];

const ReservationSummaryTable = ({entries: reservationEntries, PONumberValue, showContractPrice}) => {
    const isRealTimePricingEnabled = process.env.IS_REAL_TIME_PRICING_ENABLED === 'true';

    const productRows = useMemo(() => {

        if(isRealTimePricingEnabled) {
            return reservationEntries?.filter(row => row?.doses !== 0)
                ?.map((row, index) => ({
                    id: index,
                    title: row?.productName,
                    price: row?.listPrice,
                    contractPrice: row?.contractPrice,
                    listPriceValue: row?.listPriceValue,
                    contractPriceValue: row?.contractPriceValue,
                    doses: row?.doses
            })) ?? [];
        } else {
            return reservationEntries?.filter(row => row?.doses !== 0)
                ?.map((row, index) => ({
                    id: index,
                    title: row?.productName,
                    price: row?.listPrice,
                    listPriceValue: row?.listPriceValue,
                    contractPrice: UNAVAILABLE,
                    doses: row?.doses
            })) ?? [];
        }
    }, [reservationEntries]);

    const getTotalDoses = () => {
        return productRows?.reduce((sum, item) => sum + item.doses, 0)
    }

    const getTotalPrice = () => {
        if (isRealTimePricingEnabled) {
            const prices = productRows?.reduce((totals, item) => {
                    totals.listPrice += Number(item.listPriceValue) * item.doses;
                    totals.contractPrice += Number(item.contractPriceValue) * item.doses;
                    return totals;
            }, { listPrice: 0, contractPrice: 0 });
            
            return prices;
        } else {
            return productRows?.reduce((sum, item) => sum + (Number(item.listPriceValue) * item.doses), 0)
        }
    }

    return (
        <Box>
            <BoxContainer display='flex' flexDirection={'column'}>
                <Box mt={4} width='100%'>
                    <TypographyHeading>{reservationEntries?.[0].address?.dba}</TypographyHeading>
                    <TypographyText>{formatAddresses(reservationEntries?.[0]?.address)}</TypographyText>
                    { PONumberValue &&
                        <TypographyText><span style={{'fontWeight': '700'}}>PO Number:</span> {PONumberValue}</TypographyText>
                    }
                    <DataGrid
                        autoHeight
                        rowHeight={40}
                        rows={productRows}
                        columns={columns}
                        hideFooter
                        disableColumnFilter
                        disableColumnMenu
                    />
                    <Divider orientation="horizontal" variant="middle" sx={{ marginTop: 2 }} />
                    <Grid display="flex" justifyContent="right" alignItems="center" mt={2}>
                        <TypographySummaryText style={{width:'120px'}}>{getTotalDoses()}</TypographySummaryText>
                        <TypographySummaryText style={{width:'130px'}}>{isRealTimePricingEnabled ? formattedPrice(getTotalPrice().listPrice) : formattedPrice(getTotalPrice())}</TypographySummaryText>
                        <TypographySummaryText style={{width:'140px'}}>{isRealTimePricingEnabled && showContractPrice ? formattedPrice(getTotalPrice().contractPrice) : UNAVAILABLE}</TypographySummaryText>
                    </Grid>
                </Box>
            </BoxContainer>
        </Box>
    )
}

export default ReservationSummaryTable